import React from "react";
import { graphql, Link } from "gatsby";

import { useStyletron } from "baseui";
import { Button, KIND } from "baseui/button";
import { ChevronLeft } from "baseui/icon";

import { Heading, HeadingLevel } from "baseui/heading";
import { StyledLink } from "baseui/link";
import { Label1, Paragraph1 } from "baseui/typography";
import { Accordion, Panel } from "baseui/accordion";
import { LocaleProvider } from "baseui";
import { ru_RU } from "../locale/ru_RU";

import Page from "../components/internal-page";
import Calc from "../components/calc";
import CheckOKVED from "../components/check-okved";

const programsTemplate = ({
  data: {
    programsMkk: {
      key,
      name,
      description,
      interestRate,
      loanTerm,
      maxAmount,
      maxGracePeriod,
      isGraceDisabled,
      okved,
    },
  },
}) => {
  const [css, theme] = useStyletron();
  const space = css({ marginLeft: theme.sizing.scale800 });

  const okvedListAllowed = okved.allow?.join(", ");
  const okvedListDenied = okved.deny?.join(", ");

  return (
    <Page
      title={`Программа ${name}`}
      description={`Программа микрокредитования субъектов малого и среднего предпринимательства - ${name}`}
      path="/programs"
    >
      <HeadingLevel>
        <HeadingLevel>
          <Heading styleLevel={4}>Участники программы</Heading>
          <Paragraph1>{description}</Paragraph1>

          <LocaleProvider locale={ru_RU}>
            <Accordion
              renderAll
              overrides={{
                PanelContainer: {
                  style: () => ({ borderTop: "1px solid rgb(226, 226, 226)" }),
                },
              }}
            >
              <Panel title="Перечень видов экономической деятельности (ОКВЭД)">
                {okved.any && (
                  <Paragraph1>
                    Без ограничений по видам экономической деятельности.
                  </Paragraph1>
                )}

                {okvedListAllowed && (
                  <>
                    <Label1>
                      Включенные{" "}
                      {okved.main && (
                        <>
                          <u>основные</u>{" "}
                        </>
                      )}
                      коды экономической деятельности:
                    </Label1>
                    <Paragraph1>{okvedListAllowed}</Paragraph1>
                  </>
                )}
                {okvedListDenied && (
                  <>
                    <Label1>
                      Исключенные коды экономической деятельности:
                    </Label1>
                    <Paragraph1>{okvedListDenied}</Paragraph1>
                  </>
                )}
              </Panel>
            </Accordion>
          </LocaleProvider>
          {okved.main && (
            <>
              <Heading styleLevel={4}>
                Проверить возможность получения микрозайма
              </Heading>
              <CheckOKVED
                title={`Программа ${name}`}
                okvedList={okved.allow}
                programName={name}
                ignoreDate={true}
              />
            </>
          )}

          <Heading styleLevel={4}>Условия программы</Heading>

          <Paragraph1>
            Сумма до {(maxAmount || 5_000_000).toLocaleString("ru-RU")} руб.
            <br />
            Ставка {interestRate.toLocaleString("ru-RU")}%<br />
            Срок до {loanTerm || 24} мес.
            <br />
            {isGraceDisabled
              ? "Без льготного периода"
              : `Льготный период до ${
                  maxGracePeriod || (loanTerm || 24) - 1
                } мес.`}
          </Paragraph1>

          <Heading styleLevel={4}>Расчет платежей</Heading>
          <Calc
            programId={key}
            externalAmount={maxAmount}
            externalTerm={loanTerm}
          />
          <Heading styleLevel={4}>Приложения</Heading>
          <Paragraph1>
            <StyledLink
              href="https://drive.google.com/file/d/1rEMQ0TA9mPbv2cTsuwUsXnrmY3_u0sji/view"
              target="_blank"
              rel="noopener noreferrer"
            >
              Правила предоставления микрозаймов
            </StyledLink>
          </Paragraph1>
          <div
            className={css({
              marginTop: "40px",
              display: "flex",
              alignContent: "center",
            })}
          >
            <Button
              $as={Link}
              to="/programs"
              startEnhancer={<ChevronLeft size={24} />}
              kind={KIND.secondary}
            >
              Все программы
            </Button>
            <span className={space} />
            <Button $as={Link} to="/apply" kind={KIND.primary}>
              Подать заявку
            </Button>
          </div>
        </HeadingLevel>
      </HeadingLevel>
    </Page>
  );
};
export default programsTemplate;

export const query = graphql`
  query ($slug: String) {
    programsMkk(slug: { eq: $slug }) {
      key
      name
      description
      interestRate
      loanTerm
      maxAmount
      maxGracePeriod
      isGraceDisabled
      okved {
        any
        main
        allow
        deny
      }
      links {
        self {
          href
        }
      }
    }
  }
`;
